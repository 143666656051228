(function ($) {
  /*
   *  new_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$el (jQuery element)
   *  @return	n/a
   */

  function new_map($el) {
    // var
    var $markers = $el.find(".marker");

    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      streetViewControl: false,
      styles: [
        { featureType: "administrative", stylers: [{ visibility: "off" }] },
        { featureType: "poi", stylers: [{ visibility: "simplified" }] },
        { featureType: "road", stylers: [{ visibility: "simplified" }] },
        { featureType: "water", stylers: [{ visibility: "simplified" }] },
        { featureType: "transit", stylers: [{ visibility: "simplified" }] },
        { featureType: "landscape", stylers: [{ visibility: "simplified" }] },
        { featureType: "road.highway", stylers: [{ visibility: "off" }] },
        { featureType: "road.local", stylers: [{ visibility: "on" }] },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "water",
          stylers: [{ color: "#d6eff4" }, { lightness: 52 }],
        },
        { stylers: [{ saturation: -77 }] },
        { featureType: "road" },
      ],
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);

    // return
    return map;
  }

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$marker (jQuery element)
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr("data-lat"),
      $marker.attr("data-lng")
    );

    // create marker

    var imgURL =
      "https://www.powgarden.gg/wp-content/themes/qpid-pow-garden/dist/images/pow-garden-map-marker.svg";
    var img = {
      url: imgURL,
      scaledSize: new google.maps.Size(43, 64), // scaled size
    };

    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: img,
      optimized: false,
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html(),
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(map, marker);
      });
    }
  }

  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );

      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(18);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /*
   *  document ready
   *
   *  This function will render each map when the document is ready (page has loaded)
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	5.0.0
   *
   *  @param	n/a
   *  @return	n/a
   */
  // global var
  var map = null;

  $(document).ready(function () {
    $("#pow-map").each(function () {
      // create map
      map = new_map($(this));
    });
  });
})(jQuery);
